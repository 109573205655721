// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-id-700-index-js": () => import("./../../../src/pages/id700/index.js" /* webpackChunkName: "component---src-pages-id-700-index-js" */),
  "component---src-pages-id-700-support-js": () => import("./../../../src/pages/id700/support.js" /* webpackChunkName: "component---src-pages-id-700-support-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instrument-designer-index-js": () => import("./../../../src/pages/instrument-designer/index.js" /* webpackChunkName: "component---src-pages-instrument-designer-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-styles-js": () => import("./../../../src/pages/styles.js" /* webpackChunkName: "component---src-pages-styles-js" */)
}

